import React from 'react'
import './style.scss'

import VideoHTML from '../../../Video/HTML'

function ACFVideo(props) {
  // Video Type
  const video_source = props.video_source || props.videoSource
  const video_iframe = props.video_iframe || props.videoIframe
  const video_file = props.video_file?.localFile?.publicURL || props.videoFile?.mediaItemUrl
  const autoplay = (props.acf_fc_layout === 'video' || props.__typename === "Academic_Pagebuilder_Sections_Content_Block_Video") && props?.classes?.includes('autoplay')
  const revealonclick = (props.acf_fc_layout === 'video' || props.__typename === "Academic_Pagebuilder_Sections_Content_Block_Video") && props?.classes?.includes('revealonclick')
  const buttonText = (props.acf_fc_layout === 'video' || props.__typename === "Academic_Pagebuilder_Sections_Content_Block_Video") && props?.classes?.includes('caption-inside-button') ? props.wysiwyg : null

	// Returning Section
  return (
		<>
			{ video_source === 'file' ?
				<>
					{ video_file ?
						<VideoHTML autoplay={autoplay} revealonclick={revealonclick} buttonText={buttonText} file={video_file} />
					: null }
				</>
				: null }

				{ video_source === 'iframe' ?
				<>
					{ video_iframe ?
						<div className='video-inject aspect-ratio' dangerouslySetInnerHTML={{__html: video_iframe}} />
					: null }
				</>
				: null }
		</>
  )
}

export default ACFVideo